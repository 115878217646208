<template>
  <div class="main-frame">
    <div class="category-title">
      <div>홈 <img class="title-icon" src="../assets/images/icon/icon_gray_right.png"> {{ categoryTitle }}</div>
      <h2>{{ categoryTitle }}</h2>
    </div>
    <div class="center-frame">
      <div class="side-bar">
        <div class="dropdown-box">
          <button class="tools-title" @click="sideBar.tool_1 = !sideBar.tool_1">플러그인/라이브러리
            <img v-if="sideBar.tool_1" src="@/assets/images/icon/icon_black_up.png" alt="arrow">
            <img v-else src="@/assets/images/icon/icon_black_down.png" alt="arrow">
          </button>
          <slide-up-down :active="sideBar.tool_1" :duration="500">
            <div class="side-bar-item" @click="changeTab(1,1)" :class="{active: sideBar.tabSubNum_1 === 1}">S/W 플러그인</div>
            <div class="side-bar-item" @click="changeTab(1,2)" :class="{active: sideBar.tabSubNum_1 === 2}">S/W 라이브러리</div>
            <div class="side-bar-item" @click="changeTab(1,3)" :class="{active: sideBar.tabSubNum_1 === 3}">H/W,F/W 플러그인</div>
            <div class="side-bar-item" @click="changeTab(1,4)" :class="{active: sideBar.tabSubNum_1 === 4}">H/W,F/W 라이브러리</div>
          </slide-up-down>
        </div>
        <div class="dropdown-box">
          <button class="tools-title" @click="sideBar.tool_2 = !sideBar.tool_2">프로그램 패키지
            <img v-if="sideBar.tool_2" src="@/assets/images/icon/icon_black_up.png" alt="arrow">
            <img v-else src="@/assets/images/icon/icon_black_down.png" alt="arrow">
          </button>
          <slide-up-down :active="sideBar.tool_2" :duration="500">
            <div class="side-bar-item" @click="changeTab(2,1)" :class="{active: sideBar.tabSubNum_2 === 1}">S/W 패키지</div>
            <div class="side-bar-item" @click="changeTab(2,2)" :class="{active: sideBar.tabSubNum_2 === 2}">H/W,F/W 패키지</div>
            <div class="side-bar-item" @click="changeTab(2,3)" :class="{active: sideBar.tabSubNum_2 === 3}">기타 패키지</div>
          </slide-up-down>
        </div>
        <div class="dropdown-box">
          <button class="tools-title" @click="sideBar.tool_3 = !sideBar.tool_3">디자인
            <img v-if="sideBar.tool_3" src="@/assets/images/icon/icon_black_up.png" alt="arrow">
            <img v-else src="@/assets/images/icon/icon_black_down.png" alt="arrow">
          </button>
          <slide-up-down :active="sideBar.tool_3" :duration="500">
            <div class="side-bar-item" @click="changeTab(3,1)" :class="{active: sideBar.tabSubNum_3 === 1}">AI Prompt</div>
            <div class="side-bar-item" @click="changeTab(3,2)" :class="{active: sideBar.tabSubNum_3 === 2}">AI-generated Artwork</div>
            <div class="side-bar-item" @click="changeTab(3,3)" :class="{active: sideBar.tabSubNum_3 === 3}">AI Model</div>
            <div class="side-bar-item" @click="changeTab(3,4)" :class="{active: sideBar.tabSubNum_3 === 4}">기타 디자인</div>
          </slide-up-down>
        </div>
      </div>
      <div class="container">
        <div class="alignment-line">
          <p>Items {{ itemList.length }}</p>
          <div class="alignment" @click="alignment">
            최신순
            <img v-if="isLatest" src="@/assets/images/icon/icon_gray_down.png">
            <img v-else src="@/assets/images/icon/icon_gray_up.png">
          </div>
        </div>
        <div class="items-container">
          <div class="card-box" v-for="(item,i) in itemList" :key="i"
               @click="moveItemPage(item.uid)">
            <CardView :cardData="item"/>
          </div>
        </div>
      </div>
    </div>
    <div class="banner">
      <h2>라마켓에서 리소스를 공유하고<br/>
        또다른 수익을 창출 해보세요!</h2>
      <div class="banner-img">
        <img src="@/assets/images/img_theme_banner.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import CardView from "@/components/CardView.vue";
import SlideUpDown from "vue-slide-up-down";
import {firestore} from "@/firebase/firebaseConfig";

export default {
  name: 'Home',
  components: {
    CardView, SlideUpDown
  },
  data() {
    return {
      sideBar: {
        tool_1: true,
        tool_2: true,
        tool_3: true,
        tabSubNum_1: 0,
        tabSubNum_2: 0,
        tabSubNum_3: 0,
      },
      tab: 0,
      tabNum: 0,
      isLatest: true,
      pageType: this.$route.params.type,
      selectType: this.$route.params.type,
      categoryTitle: '',
      subCategoryTitle: '',
      itemList: [],
      pluginList: [
        'S/W 플러그인',
        'S/W 라이브러리',
        'H/W,F/W 플러그인',
        'H/W,F/W 라이브러리'
      ],
      programList: [
        'S/W 패키지',
        'H/W,F/W 패키지',
        '기타 패키지'
      ],
      designList: [
        'AI Prompt',
        'AI-generated Artwork',
        'AI Model',
        '기타 디자인',
      ]
    }
  },
  watch: {
    $route() {
      this.pageType = this.$route.params.type
      this.init()
    },
    pageType() {
    },
    'sideBar.tabSubNum_1'() {
      if (this.pageType !== this.selectType)
        if (this.sideBar.tabSubNum_1 !== 0) this.$router.push({name: 'Category', params: {type: 'plugin'}})
    },
    'sideBar.tabSubNum_2'() {
      if (this.pageType !== this.selectType)
        if (this.sideBar.tabSubNum_2 !== 0) this.$router.push({name: 'Category', params: {type: 'program'}})
    },
    'sideBar.tabSubNum_3'() {
      if (this.pageType !== this.selectType)
        if (this.sideBar.tabSubNum_3 !== 0) this.$router.push({name: 'Category', params: {type: 'design'}})
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    async init() {
      const self = this;
      self.sideBar.tabSubNum_1 = 0
      self.sideBar.tabSubNum_2 = 0
      self.sideBar.tabSubNum_3 = 0

      self.tab = 1
      self.tabNum = 1
      switch (self.pageType) {
        case 'plugin':
          self.categoryTitle = '플러그인/라이브러리';
          self.sideBar.tabSubNum_1 = 1
          self.subCategoryTitle = self.pluginList[0]
          break;
        case 'program':
          self.categoryTitle = '프로그램 패키지';
          self.sideBar.tabSubNum_2 = 1
          self.subCategoryTitle = self.programList[0]
          break;
        case 'design':
          self.categoryTitle = '디자인';
          self.sideBar.tabSubNum_3 = 1
          self.subCategoryTitle = self.designList[0]
          break;
      }
      await self.getItem()
    },
    getItem() {
      const self = this;

      self.itemList.splice(0)
      firestore.collection('item')
          .where('category', '==', self.pageType)
          .where('subCategory', '==', self.subCategoryTitle)
          .orderBy('lastDate', 'desc')
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              const _data = doc.data()
              const date = new Date(_data.lastDate.seconds * 1000);
              _data.lastDate = date;
              _data['uid'] = doc.id
              if (self.itemList.length === 9) return
              self.itemList.push(_data);
            })
          })
    },
    moveItemPage(value) {
      const self = this;
      self.$router.push({name: 'ItemDetail', params: {type: self.pageType, uid: value}})
    },
    alignment() {
      const self = this;
      self.isLatest = !self.isLatest

      if (self.isLatest)
        self.itemList.sort((a, b) => b.lastDate - a.lastDate);
      else
        self.itemList.sort((a, b) => a.lastDate - b.lastDate);
    },
    async changeTab(index, value) {
      const self = this;

      self.sideBar.tabSubNum_1 = 0
      self.sideBar.tabSubNum_2 = 0
      self.sideBar.tabSubNum_3 = 0

      self.tab = index
      self.tabNum = value
      self.sideBar['tabSubNum_' + index] = value
      if (index === 1) {
        self.subCategoryTitle = self.pluginList[value - 1]
        self.selectType = 'plugin'
        self.categoryTitle = '플러그인/라이브러리';
      }
      if (index === 2) {
        self.subCategoryTitle = self.programList[value - 1]
        self.selectType = 'program'
        self.categoryTitle = '프로그램 패키지';
      }
      if (index === 3) {
        self.subCategoryTitle = self.designList[value - 1]
        self.selectType = 'design'
        self.categoryTitle = '디자인';
      }
      await self.getItem()
    }
  },
}
</script>
<style scoped>

.main-frame {
  width: 1224px;
  margin: 0 auto;
  padding: 60px 16px;
}

.main-frame .category-title {
  text-align: start;
  margin-bottom: 20px;
}

.main-frame .category-title div {
  color: #71717A;
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.24px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.main-frame .category-title div .title-icon {
  width: 18px;
  height: 18px;
}

.main-frame .category-title h2 {
  color: #71717A;
  font-size: 20px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.4px;
}

.main-frame .center-frame {
  display: flex;
}

.main-frame .center-frame .side-bar {
  text-align: start;
  width: 234px;
  height: 100%;
  margin-right: 16px;
}

.center-frame .side-bar .dropdown-box {
  margin-bottom: 10px;
}
.center-frame .side-bar .tools-title {
  height: 52px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 16px;
  align-items: center;
  border-radius: 20px;
  border: 1px solid #E4E4E7;
  background: #ffffff;
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.28px;
}

.center-frame .side-bar .tools-title img {
  width: 24px;
  height: 24px;
}
.center-frame .side-bar .side-bar-item {
  height: 42px;
  padding: 0 16px;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.28px;
  display: flex;
  align-items: center;
  color: #71717A;
  cursor: pointer;
}

.center-frame .side-bar .side-bar-item.active {
  color: #FF8A00;
}

.center-frame .container {
  width: 942px;
  padding: 0;
}

.center-frame .container .alignment-line {
  display: flex;
  justify-content: space-between;
  height: 52px;
  padding: 0 16px;
  align-items: center;
  border-radius: 20px;
  border: 1px solid #E4E4E7;
  margin-bottom: 30px;
  color: #A1A1AA;
  font-size: 13px;
  font-weight: 500;
  line-height: normal;
}

.center-frame .container .alignment-line .alignment {
  font-size: 14px;
  font-weight: 700;
  letter-spacing: -0.28px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.center-frame .container .alignment-line .alignment img {
  width: 18px;
  height: 18px;
  margin-left: 6px;
}

.center-frame .container .items-container {
  padding: 0 16px;
  margin-bottom: 60px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 20px;
  row-gap: 30px;
}


.banner {
  height: 286px;
  background: #FBF4EB;
  padding: 0 130px;
  display: flex;
  justify-content: space-between;
  align-items: end;
  border-radius: 20px;
}

.banner h2 {
  font-size: 30px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.6px;
  margin: auto 0;
}

.banner .banner-img {
  width: 420px;
  height: 270px;
}

.banner .banner-img img {
  width: 100%;
  height: 100%;
}
</style>